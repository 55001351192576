import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logo from "../assets/ConcienciaDigitalLogo1.png";
import CustomButton from "../components/CustomButton";
import QuestionsAccordeon from "../components/QuestionsAccordeon";

const HomeScreen: React.FC = () => {
  const [logoLoaded, setLogoLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = logo;
    img.onload = () => setLogoLoaded(true);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box textAlign="center" py={5}>
          {logoLoaded ? (
            <img
              src={logo}
              alt="Conciencia Digital Logo"
              style={{ height: "350px", width: "auto", marginRight: "15px" }}
            />
          ) : (
            <Box
              sx={{
                height: "350px",
                width: "350px",
                backgroundColor: "#e0e0e0",
                marginRight: "15px",
              }}
            />
          )}

          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            Plataforma de recursos que busca promover y elevar la conciencia del
            consumo digital.
          </Typography>
          <CustomButton
            label="Ver más"
            to="/articulos"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>

        <QuestionsAccordeon />

        <Box sx={{ height: "200px", backgroundColor: "#f0f0f0" }} />

        <Box
          sx={{
            paddingBottom: 5,
            marginTop: -10,
            textAlign: "center",
            backgroundColor: "#f0f0f0",
          }}
        >
          <Typography
            id="nosotros"
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            Sobre Nosotros
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Bienvenido a Conciencia Digital</strong>, donde promovemos
            un uso seguro de la tecnología. Nos dedicamos a educar y proteger a
            las personas de los riesgos del cibercrimen, como el ciberbullying y
            el grooming.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Nuestra misión</strong> es empoderar a individuos y
            comunidades con herramientas y conocimientos para navegar el mundo
            digital de manera segura y consciente.
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            ¡Únete a nosotros y sigue nuestras iniciativas en redes sociales!
            <br />
            <strong>#AntiCiberBullying</strong>,{" "}
            <strong>#LuchaContraElGrooming</strong>,{" "}
            <strong>#AntiEstafasDigitales</strong>,{" "}
            <strong>#HackersEticos</strong>
          </Typography>

          <CustomButton
            label="Apoyar"
            to="/comunidad"
            onClick={() => console.log("Button clicked!")}
            sx={{ mt: 4 }}
          />
        </Box>

        <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      </Container>

      <Footer />
    </div>
  );
};

export default HomeScreen;
