import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselComponent from "../components/CarouselComponent";
import QuoteComponent from "../components/QuoteComponent";
import freecodeCampIMG from "../assets/freecodecamp.png";

const educationData1 = [
  {
    title: "Test Anti Phishing",
    description: "Jigsaw Game realizado por Google. | **Gratis**",
    link: "https://phishingquiz.withgoogle.com/",
    image: "https://phishingquiz.withgoogle.com/static/share-7e4bdf41.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Test contra CYBERBULLYING",
    description: "Quiz interacitvo realizado por Wordwall.net | *Gratis*",
    link: "https://wordwall.net/es/resource/3927645/ciberbullying",
    image:
      "https://wordwallscreens.azureedge.net/400/5173e56ecd7a4291adee6ff92b77b390_0", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Interland",
    description:
      "Juego educativo sobre la navegación en Internet. Google | *Gratis*",
    link: "https://beinternetawesome.withgoogle.com/en_us/interland",
    image:
      "https://www.nobbot.com/wp-content/uploads/2017/06/interland-897x515.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "CyberLegends",
    description: "CyberLegends: Plataforma de Videojuegos educativos | *Pago*",
    link: "https://www.cyberlegends.com/",
    image:
      "https://cdn.prod.website-files.com/616095efa1539da414f6e3cd/61723126b3856781a4cd2ec2_cyber-meta.png", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Nova LABS",
    description:
      "Juego integrado en un Curso del Laboratorio de Ciberseguridad | *Pago*",
    link: "https://www.pbs.org/wgbh/nova/labs/lab/cyber/",
    image: "https://www.pbs.org/wgbh/nova/labs/media/content/images/video.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
];

const educationData2 = [
  {
    title: "FreeCodeCamp",
    description:
      "Explora cursos y programas para adquirir habilidades en diversos roles dentro del campo de la Seguridad de la Información. *Gratis*.",
    link: "https://www.freecodecamp.org/espanol/learn/information-security/",
    image: freecodeCampIMG,
  },
  {
    title: "E-volve",
    description:
      "E-volve es una sección de juegos educativos creada por Common Sense Media ORG | *Gratis*",
    link: "https://www.commonsense.org/education",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0QGbq0y8pwVPqXoo2bBVk6OZh-JWwEbC2OqLrh267JJmbpuQ6NFDrj9dFyLnpFG9KX3w&usqp=CAU",
  },

  {
    title: "Platzi",
    description:
      "Escuela de Ciberseguridad dictada por la Plataforma Educativa Online Platzi. | *Pago*",
    link: "https://platzi.com/escuela/ciberseguridad/",
    image:
      "https://latamlist.com/wp-content/uploads/2018/05/platzi-logo-huge.png", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Fundamentos de Ciberseguridad para usuarios",
    description:
      "Curso de Fundamentos de ciberseguridad para usuarios dicatdo por la UPV valencia. | *Gratis*",
    link: "https://www.edx.org/learn/computer-programming/universitat-politecnica-de-valencia-fundamentos-de-ciberseguridad-para-usuarios?index=product&queryID=4d6210ad650b1a3de12881e1b32757c6&position=2&results_level=first-level-results&term=ciber&objectID=course-9a420cf1-7838-49f3-86af-0b503d7972f3&campaign=Fundamentos+de+ciberseguridad+para+usuarios&source=edX&product_category=course&placement_url=https%3A%2F%2Fwww.edx.org%2Fsearch",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4IqmTVUDlmCQuQ6FVDTmwjWA96zIQc1e2JQ&s", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: " Certificado de Ciberseguridad de Google Professional",
    description:
      "Curso gratuito dicatado en la plataforma Coursera, donde aprenderas SQL,Python, Linux y podras recibir un certificado. | **Gratis**",
    link: "https://grow.google/intl/es/google-career-certificates/cybersecurity/",
    image:
      "https://storage.googleapis.com/grow-with-goog-publish-prod-media/images/New_GWG_site__800_x_421_px_1.original.format-jpeg.jpg",
  },
];

const EducationScreen: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem", // Tamaño para pantallas extra pequeñas
              sm: "2rem", // Tamaño para pantallas pequeñas
              md: "2.5rem", // Tamaño para pantallas medianas
              lg: "3rem", // Tamaño para pantallas grandes
              xl: "3.5rem", // Tamaño para pantallas extra grandes
            },
            textAlign: "center",
          }}
        >
          Recursos Educativos
        </Typography>
        <CarouselComponent cardData={educationData2} cardsPerPage={2} />
        <QuoteComponent
          quote="La educación es el arma más poderosa para cambiar el mundo."
          author="Nelson Mandela"
        />
        <CarouselComponent cardData={educationData1} cardsPerPage={2} />
        <QuoteComponent
          quote="El aprendizaje es experiencia, todo lo demás es información."
          author="Albert Einstein"
        />
      </Container>
      <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default EducationScreen;
