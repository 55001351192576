import React, { useState } from "react";
import { Container, Typography, Box, Modal } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import PomodoroTimer from "../components/PomodoroTimer";

const CommunityScreen: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar el modal

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box textAlign="center" py={5}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              mb: 3,
            }}
          >
            Cada Aporte Cuenta
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, mb: 4 }}>
            ¡Tu apoyo es clave para promover la conciencia digital! Con cada
            contribución, ayudas a mantener este sitio web accesible y a
            fortalecer la seguridad en el entorno digital.
            <br />
            <strong>
              Únete a nuestro esfuerzo por un mundo digital más seguro.
            </strong>
          </Typography>

          <CustomButton
            label="Únete"
            to="https://buymeacoffee.com/concienciadigital"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mt: 2, mb: 4, textAlign: "justify" }}
        >
          Toma un respiro y mejora tu enfoque con nuestro{" "}
          <strong>Digital Break Timer</strong>.
          <br />
          Dedica un tiempo a concentrarte en lo que importa, mejora tu
          productividad y alcanza tus objetivos.
          <br />
        </Typography>

        <CustomButton
          label="Quiero un Break"
          onClick={handleOpenModal}
          sx={{ mt: 4, mb: { xs: 8, sm: 4 } }} // Mayor margen inferior en pantallas móviles
        />

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh", // Para centrar en pantalla
              px: 2,
            }}
          >
            <PomodoroTimer onClose={handleCloseModal} />
          </Box>
        </Modal>
      </Container>
      <Footer />
    </div>
  );
};

export default CommunityScreen;
