import { createTheme } from '@mui/material/styles';

// Crea un tema con Poppins como fuente principal
const Theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  // Puedes añadir otras configuraciones del tema aquí
});

export default Theme;
