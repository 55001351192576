import React, { useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MediaCard from "./MediaCard";
import { motion } from "framer-motion";

interface CarouselComponentProps {
  cardData: {
    title: string;
    description: string;
    link: string;
    image: string;
  }[];
  cardsPerPage?: number;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({
  cardData,
  cardsPerPage = 3,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(cardData.length / cardsPerPage);
  const cardWidth = 250; // Ancho de la tarjeta
  const containerWidth = cardWidth * cardsPerPage; // Ancho del contenedor

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "420px",
        width: "100%",
        marginTop: "40px",
      }}
    >
      <IconButton
        onClick={handlePrevPage}
        sx={{ margin: 5 }}
        disabled={currentPage === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Box
        sx={{
          width: `${containerWidth}px`,
          height: "100%",
          overflow: "hidden",
        }}
      >
        <motion.div
          initial={{ x: 300 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              width: `${cardData.length * cardWidth}px`,
              transform: `translateX(-${currentPage * containerWidth}px)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {cardData.map((data, index) => (
              <MediaCard
                key={index}
                title={data.title}
                description={data.description}
                link={data.link}
                image={data.image}
              />
            ))}
          </Stack>
        </motion.div>
      </Box>
      <IconButton
        onClick={handleNextPage}
        sx={{ margin: 5 }}
        disabled={currentPage >= totalPages - 1}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default CarouselComponent;
