import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselComponent from "../components/CarouselComponent";
import QuoteComponent from "../components/QuoteComponent";

const articleData1 = [
  {
    title: "Keylogging Attack",
    description:
      "keylogging es conocida como Acoustic Keyboard Eavesdropping Keylogging Attack (Ataque de espionaje a través del teclado acústico).",
    link: "https://www.infobae.com/economia/2024/08/08/keylogging-como-funciona-la-nueva-amenaza-virtual-que-vacia-cuentas-bancarias-con-solo-tocar-una-tecla/",
    image:
      "https://www.redeszone.net/app/uploads-redeszone.net/2022/01/keylogger.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "InfoSecMap",
    description:
      "Anuncios de Eventos y grupos de Ciberseguridad a nivel Internacional.",
    link: "https://infosecmap.com/",
    image:
      "https://infosecmap.com/wp-content/uploads/2020/11/InfoSecMap-round.png", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Julian Assange: Is FREE!",
    description:
      "Articulo de Liberación de Julian Assange creador de Wikileaks",
    link: "https://www.reddit.com/r/Libertarian/comments/1dnta8t/julian_assange_is_free/?rdt=54995",
    image:
      "https://preview.redd.it/julian-assange-is-free-v0-rvodwm377m8d1.jpeg?auto=webp&s=a82534b941f88ba37a83f2193d5c60c872aaeba6", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Vigilancia Masiva: Edward Snowden",
    description:
      "Cronología del caso de Snowden y su denuncia al gobierno de EE. UU.",
    link: "https://www.britannica.com/biography/Edward-Snowden",
    image: "https://www.publico.es/uploads/2019/09/23/5d88b8c08e689.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Pegasus Spyware",
    description:
      "Cronología del caso de Snowden y su denuncia al gobierno de EE. UU.",
    link: "https://nordvpn.com/blog/pegasus-spyware/",
    image:
      "https://nordvpn.com/wp-content/uploads/blog-featured-pegasus-spyware.svg", // Asegúrate de reemplazar con la ruta correcta
  },
];

const articleData2 = [
  {
    title: "ITAA: Asociacion para adictos al Internet y Tecnología.",
    description: "Adictos a Internet y la Tecnología Anónimos.",
    link: "https://internetaddictsanonymous.org/es/espanol/",
    image:
      "https://internetaddictsanonymous.org/wp-content/uploads/2023/01/cropped-ITAA-logo-480px.png", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Privacidad y seguridad en redes sociales",
    description: "Consejos para mantener tu perfil seguro en redes sociales.",
    link: "https://www.iberdrola.com/innovacion/redes-sociales-privacidad-seguridad",
    image: "https://www.iberdrola.com/documents/20125/39730/IB_363x205_esp.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "Derechos Digitales: Latam",
    description:
      "Derechos Digitales es una organización de alcance latinoamericano, independiente y sin fines de lucro, fundada en 2005 y que tiene como objetivo fundamental el desarrollo, la defensa y la promoción de los derechos humanos en el entorno digital.",
    link: "https://www.derechosdigitales.org/",
    image:
      "https://blog.ida.cl/wp-content/uploads/sites/5/2017/06/logos-11_29_905.png", // Asegúrate de reemplazar con la ruta correcta
  },
  {
    title: "IA y Propiedad Intelectual",
    description:
      "Una guía completa sobre Inteligencia artificial y propiedad intelectual.",
    link: "https://www.wipo.int/about-ip/es/artificial_intelligence/",
    image: "https://aecem.es/wp-content/uploads/inteligencia-artificial.jpg",
  },
  {
    title: "Hacking ético",
    description: "Hacking ético: ¿en qué consiste?.",
    link: "https://www.telefonica.com/es/sala-comunicacion/blog/hacking-etico-que-es/",
    image:
      "https://www.telefonica.com/en/wp-content/uploads/sites/5/2021/10/telefonica-logo-horizontal-negativo-1250.jpg", // Asegúrate de reemplazar con la ruta correcta
  },
];

const ArticlesScreen: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem", 
              sm: "2rem", 
              md: "2.5rem", 
              lg: "3rem", 
              xl: "3.5rem", 
            },
            textAlign: "center", // Opcional: Centra el texto en pantallas más pequeñas
          }}
        >
          {" "}
          Artículos Informativos
        </Typography>
        <CarouselComponent cardData={articleData2} cardsPerPage={2}/>
        <QuoteComponent
          quote="La privacidad es un derecho, no un privilegio. No es algo que deba ser jusitificado, sino algo que debe ser defendido."
          author="Edward Snowden"
        />
        <CarouselComponent cardData={articleData1} cardsPerPage={2} />
        <QuoteComponent
          quote="Si las guerras pueden empezar con mentiras, la paz puede empezar con la verdad."
          author="Julian Assange"
        />
      </Container>
      <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default ArticlesScreen;
