import React from 'react';
import ReactDOM from 'react-dom/client'; // Importar desde 'react-dom/client'
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import './index.css'; // CSS global si es necesario
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// Si deseas medir el rendimiento en tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un punto final de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();
